<template>
  <BaseLayout>
    <Breadcrumbs />
    <div class="container">
      <b-row class="forgot-password">
        <b-col md="10" lg="7">

          <!-- Heading -->
          <h1>{{ $t("password_reset") }}</h1>
          <p>
            {{ $t("reset_password_text") }}
          </p>
          <input type="hidden" id="token" v-model="token" />

          <!-- Email -->
          <b-form-group
            class="account-inputs"
            :label="$t('form_email') + '*'"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="email"
              type="text"
              :state="emailState"
              class="account-inputs"
              aria-describedby="email-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="email-feedback">
              {{ emailError }}
            </b-form-invalid-feedback>
          </b-form-group>

          <!-- Password -->
          <b-form-group
            class="account-inputs"
            id="password-group"
            :label="$t('form_password') + '*'"
            label-for="password"
          >
            <b-form-input
              id="password"
              v-model="password"
              type="password"
              :state="passwordState"
              aria-describedby="password-feedback"
            ></b-form-input>
            <client-only>
              <password-meter :password="password" />
            </client-only>
            <b-form-invalid-feedback id="password-feedback">
              {{ passwordError }}
            </b-form-invalid-feedback>
          </b-form-group>

          <!-- Confirm Password -->
          <b-form-group
            class="account-inputs"
            id="password_confirm-group"
            :label="$t('form_password_confirmation') + '*'"
            label-for="password_confirm"
          >
            <b-form-input
              id="password_confirm"
              v-model="password_confirm"
              type="password"
              :state="password_confirmState"
              aria-describedby="password_confirm-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="password_confirm-feedback">
              {{ password_confirmError }}
            </b-form-invalid-feedback>
          </b-form-group>

          <!-- Message -->
          <div>{{ description }}</div>

          <!-- Submit/Back Button -->
          <div
            class="btn-wrap d-flex justify-content-md-between align-items-md-center"
          >
            <b-link class="back" href="/login">{{ $t("go_back") }}</b-link>
            <div class="btn-holder">
              <b-button
                @click="formSubmit"
                variant="info"
                class="text-white form-submit-btn"
                >{{ $t("reset_my_password") }}</b-button
              >
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </BaseLayout>
</template>

<script>
import BaseLayout from "@/esf_lommel_dt1_europe/core/components//BaseLayout";
import Breadcrumbs from "@/esf_lommel_dt1_europe/core/components//Breadcrumbs";
import { resetPassword } from "@storefront/core/data-resolver/user";
import ClientOnly from "vue-client-only";
import { isServer } from "@storefront/core/helpers";

export default {
  name: "ResetPassword",
  components: {
    BaseLayout,
    Breadcrumbs,
    passwordMeter: () =>
      !isServer ? import("vue-simple-password-meter") : null,
    ClientOnly,
  },
  mounted() {
    const bcrumb = { current: this.$t("password_reset"), routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
  },
  metaInfo() {
    return {
      meta: [
        { name: "title", content: this.$t("password_reset") },
        { name: "keywords", content: this.$t("password_reset") },
        { name: "description", content: this.$t("password_reset") },
      ],
      title: this.$t("password_reset"),
    };
  },
  data() {
    return {
      email: "",
      emailState: null,
      emailError: "",
      description: "",
      password: "",
      passwordState: null,
      passwordError: "",
      password_confirm: "",
      password_confirmState: null,
      password_confirmError: "",
      token: this.$route.query.token,
      tokenState: null,
      tokenError: "",
      emailReg:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    };
  },
  computed: {
    Email() {
      return this.email;
    },
    Password() {
      return this.password;
    },
    PasswordConfirm() {
      return this.password_confirm;
    },
  },
  watch: {
    Email() {
      this.validateEmail();
    },
    Password() {
      this.validatePassword();
    },
    PasswordConfirm() {
      this.validateConfirmationPassword();
    },
  },
  methods: {

    // Submit form
    async formSubmit() {
      if (
        this.validateEmail() &&
        this.validatePassword() &&
        this.validateConfirmationPassword() &&
        this.validateToken()
      ) {
        const retval = await resetPassword(
          this.email,
          this.token,
          this.password
        );
        if (retval == true) {
          this.description = this.$t("password_resetted");
          this.resetFormFields();
          setTimeout(this.resetFormStates, 0);
        } else {
          this.description = this.$t("password_not_resetted");
        }
      }
    },

    resetFormFields() {
      this.email = "";
      this.password = "";
      this.password_confirm = "";
      this.token = "";
    },

    resetFormStates() {
      this.emailState = null;
      this.password_confirmState = null;
      this.passwordState = null;
      this.tokenState = null;
      this.emailError = "";
    },
    validatePassword() {
      if (this.password.length < 8) {
        this.passwordState = false;
        this.passwordError = this.$t("password_to_short");
      } else if (this.password.match(/[a-z]/) == null) {
        this.passwordState = false;
        this.passwordError = this.$t("password_needs");
      } else if (
        this.password.match(/[!"#$%&'()*+,-.:;<=>?@[\]^_`{|}~]/) == null
      ) {
        this.passwordState = false;
        this.passwordError = this.$t("password_needs");
      } else if (this.password.match(/[A-Z]/) == null) {
        this.passwordState = false;
        this.passwordError = this.$t("password_needs");
      } else if (this.password.match(/\d/) == null) {
        this.passwordState = false;
        this.passwordError = this.$t("password_needs");
      } else {
        this.passwordState = true;
      }
      return this.passwordState;
    },
    validateConfirmationPassword() {
      if (this.password.length < 8) {
        this.password_confirmState = false;
      } else if (this.password != this.password_confirm) {
        this.password_confirmState = false;
        this.password_confirmError = this.$t("password_confirm_not_equal");
      } else {
        this.password_confirmState = true;
      }
      return this.password_confirmState;
    },
    validateToken() {
      if (this.token == "") {
        this.tokenState = false;
        this.tokenError = this.$t("token_not_filled");
        return false;
      } else {
        this.tokenState = true;
        return true;
      }
    },
    validateEmail() {
      console.log("triggered");
      if (this.email == "") {
        this.emailState = false;
        this.emailError = this.$t("email_is_required_field");
        return false;
      }
      if (!this.emailReg.test(this.email)) {
        this.emailState = false;
        this.emailError = this.$t("email_is_invalid_field");
        return false;
      }
      this.emailState = true;
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/esf_lommel_dt1_europe/scss/media-queries.scss";
@import "@/esf_lommel_dt1_europe/scss/custom-bootstrap.scss";

.forgot-password {
  font-size: 21px;
  line-height: 1.15;
  font-weight: 400;
  padding-top: 5px;

  @include media("screen", ">=tablet") {
    padding-bottom: 40px;
  }

  @include media("screen", ">=desktop") {
    padding-bottom: 59px;
  }

  [class*="col"] {
    @include media("screen", ">=tablet") {
      padding-right: 60px;
    }

    @include media("screen", ">=desktop") {
      padding-right: 90px;
    }
  }

  h1 {
    @include media("screen", ">=tablet") {
      font-size: 28px;
      margin-bottom: 36px;
    }
  }

  .form {
    padding-top: 15px;

    @include media("screen", ">=tablet") {
      padding-top: 23px;
      padding-right: 8px;
    }
  }

  .account-inputs {
    @include media("screen", ">=tablet") {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.btn-wrap {
  padding-top: 10px;
  flex-direction: column-reverse;

  @include media("screen", ">=tablet") {
    padding-top: 20px;
    flex-direction: row;
  }
}

.btn-holder {
  padding-bottom: 20px;

  @include media("screen", ">=tablet") {
    padding-bottom: 0;
  }
}

.back {
  font-size: 16px;
  font-weight: 700;
  padding-left: 16px;
  position: relative;

  &:hover {
    &:before {
      border-color: $orange;
    }
  }

  &:before {
    content: "";
    width: 8px;
    height: 8px;
    border-top: 1px solid $black;
    border-left: 1px solid $black;
    transition: border 0.3s linear;
    transform: rotate(-45deg);
    position: absolute;
    left: 0;
    top: 5px;
  }
}
</style>

<style lang="scss" >
@import "@/esf_lommel_dt1_europe/scss/media-queries.scss";

.forgot-password {
  label {
    font-size: 16px;
    white-space: nowrap;
    margin-bottom: 20px;
    max-width: 120px;

    @include media("screen", ">=tablet") {
      margin-right: 47px;
      margin-bottom: 0;
    }
  }

  .bv-no-focus-ring {
    width: 100%;

    @include media("screen", ">=tablet") {
      max-width: calc(100% - 120px);
    }
  }

  -control {
    border-radius: 6px;
  }

  -submit-btn {
    border-radius: 6px;
  }

  .btn {
    border-radius: 6px !important;
    font-size: 16px;
    line-height: 1.3;
    padding-top: 9px;
    padding-bottom: 11px;
  }
}
.account-inputs {
  display: flex;
  align-items: center;
  margin-bottom: 21px;
  font-size: 14px;

  @include media("screen", ">=tablet") {
    padding-right: 14px;
    font-size: 16px;
  }

  .short-form & {
    margin-bottom: 20px;

    input {
      @include media("screen", ">=tablet") {
        max-width: 332px;
      }
    }
  }

  .first & {
    @include media("screen", ">=tablet") {
      padding-right: 0;
    }
  }

  label {
    min-width: 110px;
    width: 110px;
    font-weight: 400;
    padding-right: 2px;

    .second &,
    .third & {
      @include media("screen", ">=tablet") {
        width: 110px;
        min-width: 110px;
      }
    }
  }

  > div {
    width: 100%;
  }

  input {
    border-color: #7f7f7f;
    border-radius: 6px;
  }

  .account-radios label {
    width: 100%;
  }
}
.forgot-password label {
  margin-right: 67px;
  margin-bottom: 0;
}
</style>
