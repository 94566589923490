<template>
  <BaseLayout v-slot>
    <Breadcrumbs />
    <div class="container">
      <b-row class="forgot-password">
        <b-col md="10" lg="7">
          <h1>{{ $t("password_forgot") }}</h1>
          <p>
            {{ $t("reset_password_text") }}
          </p>
          <b-form-group
            class="account-inputs"
            id="input1-group"
            :label="$t('form_email') + '*'"
            label-for="email"
            :description="description"
          >
            <b-form-input
              id="email"
              v-model="email"
              type="text"
              :state="emailState"
              aria-describedby="email-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="email-feedback">
              {{ emailError }}
            </b-form-invalid-feedback>
          </b-form-group>
          <div
            class="btn-wrap d-flex justify-content-md-between align-items-md-center"
          >
            <b-link class="back" href="/login">{{ $t("go_back") }}</b-link>
            <div class="btn-holder">
              <b-button
                @click="formSubmit"
                variant="info"
                class="text-white form-submit-btn"
                >{{ $t("reset_my_password") }}</b-button
              >
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </BaseLayout>
</template>

<script>
import BaseLayout from "@/esf_lommel_dt1_europe/core/components//BaseLayout";
import Breadcrumbs from "@/esf_lommel_dt1_europe/core/components//Breadcrumbs";
import { forgotPassword } from "@storefront/core/data-resolver/user";
export default {
  name: "ForgotPassword",
  components: {
    BaseLayout,
    Breadcrumbs,
  },
  mounted() {
    const bcrumb = { current: this.$t("password_forgot"), routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
  },
  metaInfo() {
    return {
      meta: [
        { name: "title", content: this.$t("password_forgot") },
        { name: "keywords", content: this.$t("password_forgot") },
        { name: "description", content: this.$t("password_forgot") },
      ],
      title: this.$t("password_forgot"),
    };
  },
  data() {
    return {
      form: {
        input1: "",
      },
      email: "",
      emailState: null,
      emailError: "",
      description: "",
      emailReg:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    };
  },
  methods: {
    async formSubmit() {
      if (this.validateEmail()) {
        const retval = await forgotPassword(this.email);
        if (retval == true) {
          this.description = this.$t("reset_password_email_has_been_sent");
        } else {
          this.description = this.$t(
            "reset_password_email_could_not_been_sent"
          );
        }
      }
    },
    validateEmail() {
      if (this.email == "") {
        this.emailState = false;
        this.emailError = this.$t("email_is_required_field");
        return false;
      }
      if (!this.emailReg.test(this.email)) {
        this.emailState = false;
        this.emailError = this.$t("email_is_invalid_field");
        return false;
      }
      this.emailState = true;
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/esf_lommel_dt1_europe/scss/media-queries.scss";
@import "@/esf_lommel_dt1_europe/scss/custom-bootstrap.scss";

.forgot-password {
  font-size: 21px;
  line-height: 1.15;
  font-weight: 400;
  padding-top: 5px;

  @include media("screen", ">=tablet") {
    padding-bottom: 40px;
  }

  @include media("screen", ">=desktop") {
    padding-bottom: 59px;
  }

  [class*="col"] {
    @include media("screen", ">=tablet") {
      padding-right: 60px;
    }

    @include media("screen", ">=desktop") {
      padding-right: 90px;
    }
  }

  h1 {
    @include media("screen", ">=tablet") {
      font-size: 28px;
      margin-bottom: 36px;
    }
  }

  .form {
    padding-top: 15px;

    @include media("screen", ">=tablet") {
      padding-top: 23px;
      padding-right: 8px;
    }
  }

  .account-inputs {
    @include media("screen", ">=tablet") {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.btn-wrap {
  padding-top: 10px;
  flex-direction: column-reverse;

  @include media("screen", ">=tablet") {
    padding-top: 20px;
    flex-direction: row;
  }
}

.btn-holder {
  padding-bottom: 20px;

  @include media("screen", ">=tablet") {
    padding-bottom: 0;
  }
}

.back {
  font-size: 16px;
  font-weight: 700;
  padding-left: 16px;
  position: relative;

  &:hover {
    &:before {
      border-color: $orange;
    }
  }

  &:before {
    content: "";
    width: 8px;
    height: 8px;
    border-top: 1px solid $black;
    border-left: 1px solid $black;
    transition: border 0.3s linear;
    transform: rotate(-45deg);
    position: absolute;
    left: 0;
    top: 5px;
  }
}
</style>

<style lang="scss" >
@import "@/esf_lommel_dt1_europe/scss/media-queries.scss";

.forgot-password {
  label {
    font-size: 16px;
    white-space: nowrap;
    margin-bottom: 20px;
    max-width: 120px;

    @include media("screen", ">=tablet") {
      margin-right: 47px;
      margin-bottom: 0;
    }
  }

  .bv-no-focus-ring {
    width: 100%;

    @include media("screen", ">=tablet") {
      max-width: calc(100% - 120px);
    }
  }

  .form-control {
    border-radius: 6px;
  }

  .form-submit-btn {
    border-radius: 6px;
  }

  .btn {
    border-radius: 6px !important;
    font-size: 16px;
    line-height: 1.3;
    padding-top: 9px;
    padding-bottom: 11px;
  }
}
</style>
